import React, { useEffect } from "react";

import Main from '../components/CostsCharges/Main'
import HowMuch from '../components/CostsCharges/HowMuch'

import LazyLoad from "react-lazyload";
import Breakdown from "../components/CostsCharges/Breakdown";
import OurSpreads from "../components/CostsCharges/OurSpreads";
import OpenAccount from "../components/Home/OpenAccount";


function CostsCharges () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[60px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><HowMuch/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><Breakdown/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OurSpreads/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OpenAccount/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>    
        </div>
    );
}

export default CostsCharges;