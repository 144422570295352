import React from "react";
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import laptop from "../../img/Home/img.png";
import icon_money from "../../img/Home/icon_money.svg";
import icon_time from "../../img/Home/icon_time.svg";
import icon_chart from "../../img/Home/icon_chart.svg";

const CFD = () => {
  const { t } = useTranslation(); // Используем хук useTranslation для получения функции t для перевода текста

  return (
    <div className="max-w-[1280px] mx-auto">
      <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
        <div className="xl:max-w-[525px]">
          <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t('cfdTitle')}</h1>
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[30px] xl:mt-[35px]">{t('cfdDescription')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('tradeUsingLeverage')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('longOrShort')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('positionsAdjusted')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('tradeAroundClock')}</p>
        </div>
        <Fade right>
          <div className="xl:max-w-[548px] mt-[35px] xl:mt-0">
            <img src={laptop} alt="" />
          </div>
        </Fade>
      </div>
      <div className="mt-[90px] xl:mt-[160px]"></div>
      <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
        <div>
          <img src={icon_money} alt="" className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px]" />
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px] xl:mt-[25px]">{t('lowSpreadsAndMargins')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px] max-w-[279px]">{t('lowSpreadsDescription')}</p>
        </div>
        <div className="mt-[30px] xl:mt-0">
          <img src={icon_time} alt="" className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px]" />
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px] xl:mt-[25px]">{t('extendedHours')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px] max-w-[279px]">{t('extendedHoursDescription')}</p>
        </div>
        <div className="mt-[30px] xl:mt-0">
          <img src={icon_chart} alt="" className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px]" />
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px] xl:mt-[25px]">{t('weekendTrading')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px] max-w-[279px]">{t('weekendTradingDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default CFD;
