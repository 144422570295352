import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/CopyTrading/Main'
import UserFriendly from "../components/CopyTrading/UserFriendly";
import HowItWorks from "../components/CopyTrading/HowItWorks";
import FAQ from "../components/CopyTrading/FAQ";

function CopyTrading () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[60px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><UserFriendly/></LazyLoad> 
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><HowItWorks/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><FAQ/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default CopyTrading;