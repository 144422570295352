import React from "react";
import { useTranslation } from 'react-i18next';

import img from "../../img/Home/img2.png";
import Fade from 'react-reveal/Fade';

const FullControl = () => {
  const { t } = useTranslation(); // Получаем функцию t для перевода текста

  return (
    <div className="max-w-[1280px] mx-auto">
      <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
        <div className="xl:max-w-[525px]">
          <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t('title')}</h1>
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[30px] xl:mt-[35px]">{t('orderTypes')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('partialFills')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('gPoints')}</p>
        </div>
        <Fade right><div className="xl:max-w-[548px] mt-[35px] xl:mt-[-30px]">
          <img src={img} alt="" />
        </div></Fade>
      </div>
    </div>
  );
};

export default FullControl;
