import React from "react";

import { Trans, useTranslation } from "react-i18next";

import icon1 from '../../img/Home/icon01.svg'
import icon2 from '../../img/Home/icon02.svg'
import icon3 from '../../img/Home/icon03.svg'

function HowWork () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold text-center mx-[20px] xl:mx-0">{t("refer.does.title")}</h1>
                <p className="mt-[15px] inter text-[14px] xl:text-[16px] text-[#212121] font-medium text-center"><Trans>{t("refer.does.text1")}</Trans></p>
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[30px] xl:mt-[65px]">
                    <div className="max-w-[279px]">
                        <img src={icon1} alt="" />
                        <p className="mt-[25px] inter text-[14px] xl:text-[16px] text-[#212121] font-medium"><Trans>{t("refer.does.text2")}</Trans></p>
                        
                    </div>
                    <div className="max-w-[279px] mt-[40px] xl:mt-0">
                        <img src={icon2} alt="" />
                        <p className="mt-[25px] inter text-[14px] xl:text-[16px] text-[#212121] font-medium"><Trans>{t("refer.does.text3")}</Trans></p>
                    </div>
                    <div className="max-w-[279px] mt-[40px] xl:mt-0">
                        <img src={icon3} alt="" />
                        <p className="mt-[25px] inter text-[14px] xl:text-[16px] text-[#212121] font-medium"><Trans>{t("refer.does.text4")}</Trans></p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default HowWork;