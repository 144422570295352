import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";
import Main from "../components/AboutUs/Main";
import OurStory from "../components/AboutUs/OurStory";
import Reputable from "../components/AboutUs/Reputable";
import MeetManagers from "../components/AboutUs/MeetManagers";


function AboutUs () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[60px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><OurStory/></LazyLoad> 
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><Reputable/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><MeetManagers/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default AboutUs;