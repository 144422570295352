import React from "react";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

import icon1 from '../../img/Home/icon3.svg';
import icon2 from '../../img/Home/icon1.svg';
import icon3 from '../../img/Home/icon2.svg';

function OurPricing() {
    const { t } = useTranslation();

    return (
        <div className="max-w-[1280px] mx-auto">
            <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[30px] xl:mt-[35px]">
                <div className="flex flex-col justify-between">
                    <h1 className="text-[rgb(2,153,90)] inter text-[22px] xl:text-[35px] font-bold">{t("ourPricing.title")}</h1>
                    <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] xl:max-w-[525px] mt-[30px]">{t("ourPricing.description")}</p>
                    <a href="https://user.topforex.one" className="cursor-pointer buttontwo mt-[35px] mr-auto">
                        <div>
                            <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t("ourPricing.learnMore")}</p>
                        </div>
                    </a>
                    <Fade delay={100}>
                        <div className="xl:h-[350px] border border-solid border-gray-900 rounded-[10px] bg-[#DCF598] xl:w-[802px]"> 
                            <img src={icon1} alt="" className="mt-[10px] xl:mt-[40px] mx-[10px] xl:mx-[30px]"/>
                            <p className="mx-[10px] xl:mx-[30px] text-[#300A44] inter text-[16px] xl:text-[20px] mt-[30px]">{t("ourPricing.signalsAtAGlance")}</p>
                            <p className="pb-[10px] xl:pb-0 mx-[10px] xl:mx-[30px] text-[14px] xl:text-[16px] font-light text-[#300A44] open-sans max-w-[320px] mt-[20px] xl:mt-[30px]">{t("ourPricing.useTheInPlatformPane")}</p>
                        </div>
                    </Fade>
                </div>
                <div className="xl:w-[383px]">
                    <Fade delay={400}>
                        <div className="mt-[20px] xl:mt-0 xl:w-[383px] xl:h-[350px] border border-solid border-gray-900 rounded-[10px] bg-[#AAE3DF]">
                            <img src={icon2} alt="" className="mt-[10px] xl:mt-[40px] mx-[10px] xl:mx-[30px]" />
                            <p className="mx-[10px] xl:mx-[30px] text-[#300A44] inter text-[16px] xl:text-[20px] mt-[30px]">{t("ourPricing.fundamentalSignals")}</p>
                            <p className="pb-[10px] xl:pb-0 mx-[10px] xl:mx-[30px] text-[14px] xl:text-[16px] font-light text-[#300A44] open-sans max-w-[320px] mt-[20px] xl:mt-[30px]">{t("ourPricing.fundamentalSignalsDescription")}</p>
                        </div>
                    </Fade>
                    <Fade delay={700}>
                        <div className="mt-[20px] xl:mt-[35px] xl:w-[383px] xl:h-[350px] border border-solid border-gray-900 rounded-[10px] bg-[#F3F2F2]">
                            <img src={icon3} alt="" className="mt-[10px] xl:mt-[40px] mx-[10px] xl:mx-[30px]" />
                            <p className="mx-[10px] xl:mx-[30px] text-[#300A44] inter text-[16px] xl:text-[20px] mt-[30px]">{t("ourPricing.technicalSignals")}</p>
                            <p className="pb-[10px] xl:pb-0 mx-[10px] xl:mx-[30px] text-[14px] xl:text-[16px] font-light text-[#300A44] open-sans max-w-[320px] mt-[20px] xl:mt-[30px]">{t("ourPricing.technicalSignalsDescription")}</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default OurPricing;
