import React from "react";

import { useTranslation } from "react-i18next";

import img1 from '../../img/CopyTrading/1.svg'
import img2 from '../../img/CopyTrading/2.svg'
import img3 from '../../img/CopyTrading/3.svg'
import img4 from '../../img/CopyTrading/4.svg'
import img5 from '../../img/CopyTrading/5.svg'
import img6 from '../../img/CopyTrading/6.svg'

function UserFriendly () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
               <div className="grid grid-cols-2 xl:grid-cols-3 mx-[20px] xl:mx-0">
                    <div className="max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img1} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text4")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text5")}</p>
                    </div>
                    <div className="max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img2} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text6")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text7")}</p>
                    </div>
                    <div className="mt-[65px] xl:mt-0 max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img3} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text8")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text9")}</p>
                    </div>
                    <div className="mt-[65px] max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img4} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text10")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text11")}</p>
                    </div>
                    <div className="mt-[65px] max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img5} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text12")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text13")}</p>
                    </div>
                    <div className="mt-[65px] max-w-[161px] xl:max-w-max mx-auto">
                        <img src={img6} alt="" className="mx-auto"/>
                        <p className="inter text-[14px] xl:text-[16px] font-medium mt-[25px] text-center">{t("copytrading.text14")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light mt-[10px] max-w-[200px] text-center mx-auto">{t("copytrading.text15")}</p>
                    </div>
               </div>
           </div>
        </div>
    );
}

export default UserFriendly;