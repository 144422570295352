import React from "react";

import { useTranslation } from "react-i18next";

import girl from '../../img/AboutUs/girl.png'

import Fade from 'react-reveal/Fade'

function Reputable () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto" dir="ltr">
                <div className="relative">
                    <svg className="xl:block hidden" xmlns="http://www.w3.org/2000/svg" width="1220" height="542" viewBox="0 0 1220 542" fill="none">
                        <mask id="path-1-inside-1_306_1070" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1220 50C1220 22.3858 1197.61 0 1170 0H332C314.327 0 300 14.3269 300 32C300 49.6731 285.673 64 268 64H50C22.3858 64 0 86.3858 0 114V492C0 519.614 22.3858 542 50 542H703C719.016 542 732 529.016 732 513C732 496.984 744.984 484 761 484H1170C1197.61 484 1220 461.614 1220 434V50Z"/>
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1220 50C1220 22.3858 1197.61 0 1170 0H332C314.327 0 300 14.3269 300 32C300 49.6731 285.673 64 268 64H50C22.3858 64 0 86.3858 0 114V492C0 519.614 22.3858 542 50 542H703C719.016 542 732 529.016 732 513C732 496.984 744.984 484 761 484H1170C1197.61 484 1220 461.614 1220 434V50Z" fill="#F3F2F2"/>
                        <path d="M332 1H1170V-1H332V1ZM50 65H268V63H50V65ZM1 492V114H-1V492H1ZM703 541H50V543H703V541ZM1170 483H761V485H1170V483ZM1219 50V434H1221V50H1219ZM1170 485C1198.17 485 1221 462.167 1221 434H1219C1219 461.062 1197.06 483 1170 483V485ZM733 513C733 497.536 745.536 485 761 485V483C744.431 483 731 496.431 731 513H733ZM703 543C719.569 543 733 529.569 733 513H731C731 528.464 718.464 541 703 541V543ZM-1 492C-1 520.167 21.8335 543 50 543V541C22.9381 541 1 519.062 1 492H-1ZM50 63C21.8335 63 -1 85.8335 -1 114H1C1 86.938 22.938 65 50 65V63ZM299 32C299 49.1208 285.121 63 268 63V65C286.225 65 301 50.2254 301 32H299ZM1170 1C1197.06 1 1219 22.938 1219 50H1221C1221 21.8335 1198.17 -1 1170 -1V1ZM332 -1C313.775 -1 299 13.7746 299 32H301C301 14.8792 314.879 1 332 1V-1Z" fill="#212121" mask="url(#path-1-inside-1_306_1070)"/>
                    </svg>
                    <div className="xl:absolute xl:top-0 mx-[20px] xl:mx-0">
                        <div className="flex flex-col xl:flex-row">
                            <div className="xl:mt-[120px] xl:ml-[70px]">
                                <h1 className="max-w-[165px] inter text-[22px] xl:text-[30px] font-bold text-[#02995A]">{t("aboutus.text18")}</h1>
                                <img src={girl} alt="" className="rounded-[15px] mt-[43px] xl:block hidden"/>
                            </div>
                            <div className="xl:ml-[92px] mt-[50px]">
                                <div className="flex gap-[10px] xl:gap-[60px]">
                                    <Fade delay={200}><div className="bg-[#DCF598] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-medium inter">FCA</p>
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-light inter">(UK)</p>
                                    </div></Fade>
                                    <Fade delay={400}><div className="bg-[#F3F2F2] xl:bg-[#FFF] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-medium inter">Asic</p>
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-light inter">(Australia)</p>
                                    </div></Fade>
                                    <Fade delay={600}><div className="bg-[#F3F2F2] xl:bg-[#AAE3DF] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-medium inter">Cysec</p>
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-light inter">(Europe)</p>
                                    </div></Fade>
                                </div>
                                <div className="flex gap-[10px] xl:gap-[60px] mt-[40px]">
                                    <Fade delay={800}><div className="bg-[#F3F2F2] xl:bg-[#FFF] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-medium inter">FINRA</p>
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-light inter">(USA)</p>
                                    </div></Fade>
                                    <Fade delay={1000}><div className="bg-[#F3F2F2] xl:bg-[#3D3C3B] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] xl:text-[#FFF] text-[18px] xl:text-[25px] font-medium inter">SEC</p>
                                        <p className="text-[#212121] xl:text-[#FFF] text-[18px] xl:text-[25px] font-light inter">(USA)</p>
                                    </div></Fade>
                                    <Fade delay={1200}><div className="bg-[#F3F2F2] xl:bg-[#FFF] rounded-[15px] w-[172px] h-[120px] xl:h-[172px] flex flex-col justify-center items-center"> 
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-medium inter">FINCEN</p>
                                        <p className="text-[#212121] text-[18px] xl:text-[25px] font-light inter">(USA)</p>
                                    </div></Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Reputable;