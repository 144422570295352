import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Indices from './pages/Indices'
import Forex from './pages/Forex'
import Commodities from "./pages/Commodities";
import Cryptocurrency from "./pages/Cryptocurrency";
import Shares from "./pages/Shares";
import CostsCharges from "./pages/CostsCharges";
import ReferFriend from "./pages/ReferFriend";
import AboutUs from "./pages/AboutUs";
import CopyTrading from "./pages/CopyTrading";
import Education from "./pages/Education";
import Accounts from "./pages/Accounts";
import Regulation from "./pages/Regulation";


function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
    <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/indices" element={<Indices />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/commodities" element={<Commodities />} />
          <Route path="/cryptocurrency" element={<Cryptocurrency />} />
          <Route path="/shares" element={<Shares />} />
          <Route path="/costscharges" element={<CostsCharges />} />
          <Route path="/referfriend" element={<ReferFriend />} />
          <Route path="/copytrading" element={<CopyTrading />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/education" element={<Education />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/regulation" element={<Regulation />} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
