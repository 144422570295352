import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/AboutUs/img.png'

import Fade from 'react-reveal/Fade'

function OurStory () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
              <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-0">
                <div>
                    <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("aboutus.text12")}</span></h1>
                    <p className="mt-[30px] text-[#212121] max-w-[525px] inter font-light text-[14px] xl:text-[16px]">{t("aboutus.text13")}</p>
                </div>
                <div className="mt-[80px] xl:mt-0">
                    <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("aboutus.text14")}</span></h1>
                    <p className="mt-[30px] text-[#212121] max-w-[525px] inter font-light text-[14px] xl:text-[16px]">{t("aboutus.text15")}</p>
                </div>
              </div>
              <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-0 mt-[80px] xl:mt-[160px]">
                <div>
                    <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("aboutus.text16")}</span></h1>
                    <p className="mt-[30px] text-[#212121] max-w-[525px] inter font-light text-[14px] xl:text-[16px]">{t("aboutus.text17")}</p>
                </div>
                <Fade right><div className="mt-[80px] xl:mt-0">
                    <img src={img} alt="" className="scale-75 xl:scale-100 mx-auto xl:mx-0"/>
                </div></Fade>
              </div>
           </div>
        </div>
    );
}

export default OurStory;