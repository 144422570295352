import logo from './img/logo.svg';
import main from './img/Home/girl.png';
import main1 from './img/Home/block.png';
import main2 from './img/Home/button.png';

export const images = [
    logo,
    main,
    main1,
    main2,
];