// OurPricing.js
import React from "react";
import { useTranslation } from "react-i18next";

function OurPricing() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="max-w-[1280px] mx-auto">
                <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold mx-[20px] xl:mx-0">{t('our_pricing_heading')}</h1>
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[30px] xl:mt-[35px]">
                    <div className="xl:max-w-[525px]">
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121]">{t('comprehensive_approach_title')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('comprehensive_approach_content')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[35px]">{t('trading_experience_title')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('trading_experience_content')}</p>
                    </div>
                    <div className="xl:max-w-[525px] mt-[35px] xl:mt-0">
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121]">{t('optimal_pricing_title')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('optimal_pricing_content')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurPricing;
