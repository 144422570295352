import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import Fast from '../components/Home/Fast'
import OurPricing from '../components/Home/OurPricing'
import ProductsTrade from '../components/Home/ProductsTrade'
import CFD from '../components/Home/CFD'
import Execution from '../components/Home/Execution'
import FullControl from '../components/Home/FullControl'
import Security from '../components/Home/Security'
import AlphaFlowSignals from '../components/Home/AlphaFlowSignals'
import WhatAreTrading from '../components/Home/WhatAreTrading'
import OpenAccount from '../components/Home/OpenAccount'
import Award from '../components/Home/Award'

import LazyLoad from "react-lazyload";


function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[60px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><Fast/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OurPricing/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><ProductsTrade/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><CFD/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><Execution/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><FullControl/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><Security/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><AlphaFlowSignals/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><WhatAreTrading/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OpenAccount/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><Award/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default Home;
