import React, { useEffect } from "react";

import Main from '../components/ReferFriend/Main'


import LazyLoad from "react-lazyload";
import HowWork from "../components/ReferFriend/HowWork";
import HowReward from "../components/ReferFriend/HowReward";
import FAQ from "../components/ReferFriend/FAQ";

function ReferFriend () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[60px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><HowWork/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><HowReward/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><FAQ/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default ReferFriend;