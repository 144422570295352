import React, { useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import main from '../img/Regulation/main1.png'
import main2 from '../img/Regulation/main2.png'
import main3 from '../img/Regulation/main3.png'

import f1 from '../img/Regulation/flags/image1.png';
import f2 from '../img/Regulation/flags/image2.png';
import f3 from '../img/Regulation/flags/image3.png';
import f4 from '../img/Regulation/flags/image4.png';
import f5 from '../img/Regulation/flags/image5.png';
import f6 from '../img/Regulation/flags/image6.png';
import f7 from '../img/Regulation/flags/image7.png';
import f8 from '../img/Regulation/flags/image8.png';
import f9 from '../img/Regulation/flags/image9.png';
import f10 from '../img/Regulation/flags/image10.png';
import f11 from '../img/Regulation/flags/image11.png';
import f12 from '../img/Regulation/flags/image12.png';
import f13 from '../img/Regulation/flags/image13.png';
import f14 from '../img/Regulation/flags/image14.png';
import f15 from '../img/Regulation/flags/image15.png';
import f16 from '../img/Regulation/flags/image16.png';
import f17 from '../img/Regulation/flags/image17.png';
import f18 from '../img/Regulation/flags/image18.png';
import f19 from '../img/Regulation/flags/image19.png';
import f20 from '../img/Regulation/flags/image20.png';
import f21 from '../img/Regulation/flags/image21.png';
import f22 from '../img/Regulation/flags/image22.png';
import f23 from '../img/Regulation/flags/image23.png';
import f24 from '../img/Regulation/flags/image24.png';
import f25 from '../img/Regulation/flags/image25.png';
import f26 from '../img/Regulation/flags/image26.png';
import f27 from '../img/Regulation/flags/image27.png';

const countries = [
    { name: "Austria", flag: f1 },
    { name: "Bulgaria", flag: f2 },
    { name: "Croatia", flag: f3 },
    { name: "Czechia", flag: f4 },
    { name: "Denmark", flag: f5 },
    { name: "Estonia", flag: f6 },
    { name: "Finland", flag: f7 },
    { name: "France", flag: f8 },
    { name: "Germany", flag: f9 },
    { name: "Greece", flag: f10 },
    { name: "Hungary", flag: f11 },
    { name: "Iceland", flag: f12 },
    { name: "Ireland", flag: f13 },
    { name: "Italy", flag: f14 },
    { name: "Latvia", flag: f15 },
    { name: "Liechtenstein", flag: f16 },
    { name: "Lithuania", flag: f17 },
    { name: "Luxembourg", flag: f18 },
    { name: "Malta", flag: f19 },
    { name: "Netherlands", flag: f20 },
    { name: "Poland", flag: f21 },
    { name: "Portugal", flag: f22 },
    { name: "Romania", flag: f23 },
    { name: "Slovakia", flag: f24 },
    { name: "Slovenia", flag: f25 },
    { name: "Spain", flag: f26 },
    { name: "Sweden", flag: f27 },
  ];

function Regulation () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const { t } = useTranslation();
      
    return(
        <div className="pt-[60px]">
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[50px] xl:mt-[200px]">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("reg.1")}</span></h1>
                        <p className="mt-[30px] max-w-[525px] inter text-[16px] text-[#212121] font-light xl:mx-0 mx-[20px]">{t("reg.2")}</p>
                        <div className="cursor-pointer mx-[20px] xl:mx-0">
                            <a href="https://user.topforex.one">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t("start_now")}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[20px] xl:pt-[120px] mx-[20px] xl:mx-0">
                        <Fade duration={1000}><img src={main} alt=""/></Fade>
                    </div>
                </div>
                <div className="flex flex-col-reverse xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                    <div className="flex flex-col items-center mx-[20px] xl:mx-0 mt-[20px] xl:mt-0">
                        <Fade duration={1000}><img src={main2} alt=""/></Fade>
                    </div>
                    <div>
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("reg.3")}</span></h1>
                        <p className="mt-[30px] max-w-[525px] inter text-[16px] text-[#212121] font-light xl:mx-0 mx-[20px]">{t("reg.4")}</p>
                        <p className="mt-[15px] max-w-[525px] inter text-[16px] text-[#212121] font-medium xl:mx-0 mx-[20px]">{t("reg.5")}</p>
                        <p className="mt-[15px] max-w-[525px] inter text-[16px] text-[#212121] font-light xl:mx-0 mx-[20px]">{t("reg.6")}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                    <div>
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("reg.7")}</span></h1>
                        <p className="mt-[30px] max-w-[525px] inter text-[16px] text-[#212121] font-light xl:mx-0 mx-[20px]"><Trans i18nKey="reg.8" components={[<a href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/37585/" target="_blank" rel="noopener noreferrer" className="!font-medium underline" />]} /></p>
                        <h1 className="mt-[30px] xl:mt-[50px] mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t("reg.9")}</span></h1>
                        <p className="mt-[30px] max-w-[525px] inter text-[16px] text-[#212121] font-light xl:mx-0 mx-[20px]">{t("reg.10")}</p>
                    </div>
                    <div className="flex flex-col items-center mt-[20px] xl:mt-0 mx-[20px] xl:mx-0">
                        <Fade duration={1000}><img src={main3} alt=""/></Fade>
                    </div>
                </div>
                <div className="pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px] mx-[20px] xl:mx-0">
                    <div className="rounded-[10px] bg-[#F3F2F2] border border-[#00000080]">
                        <p className="text-[#02995A] text-center mx-auto max-w-[525px] inter text-[22px] px-[10px] xl:px-0 xl:text-[35px] font-bold mt-[20px] xl:mt-[40px]">{t("reg.11")}</p>
                        <div className="flex flex-wrap justify-center">
                            {countries.map((country, index) => (
                                <div
                                    key={index}
                                    className="m-4 flex flex-col items-center p-2 md:p-3"
                                >
                                    <img
                                        src={country.flag}
                                        alt={`${country.name} flag`}
                                        className="w-20 h-14 object-contain mb-2"
                                    />
                                    <span className="text-center font-semibold">{country.name}</span>
                                </div>
                            ))}
                        </div>
                       
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Regulation;