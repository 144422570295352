import React from "react";

import { useTranslation } from "react-i18next";

import img from "../../img/Home/img2.png"
import image from '../../img/CostsCharges/image.png'

import Fade from 'react-reveal/Fade'

function Breakdown () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
                    <div className="xl:max-w-[525px]">
                        <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t('costscharges.breakdown.title')}</h1>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[30px] xl:mt-[30px]">{t('costscharges.breakdown1')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px]">{t('costscharges.breakdown2')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('costscharges.breakdown3')}</p>
                    </div>
                    <Fade delay={100} right><div className="xl:max-w-[548px] mt-[35px] xl:mt-0">
                        <img src={img} alt="" />
                    </div></Fade>
                </div>
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[60px] xl:mt-[120px]">
                    <Fade delay={100} left><div className="xl:max-w-[548px] mt-[35px] xl:mt-0">
                        <img src={image} alt="" />
                    </div></Fade>
                    <div className="xl:max-w-[525px]">
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px]">{t('costscharges.breakdown4')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('costscharges.breakdown5')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px]">{t('costscharges.breakdown6')}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('costscharges.breakdown7')}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Breakdown;