import React from "react";

import { useTranslation } from "react-i18next";

import img from "../../img/ReferFriend/img1.png"
import image from '../../img/ReferFriend/img2.png'

import Fade from 'react-reveal/Fade'

function HowReward () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
                    <div className="xl:max-w-[525px]">
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px]">{t("refer.howreward.text1")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("refer.howreward.text2")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[15px]">{t("refer.howreward.text3")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("refer.howreward.text4")}</p>
                    </div>
                    <Fade delay={100} right><div className="xl:max-w-[548px] mt-[35px] xl:mt-0 mx-[30px] xl:mx-0">
                        <img src={img} alt="" />
                    </div></Fade>
                </div>
                <div className="flex justify-between flex-col-reverse xl:flex-row mx-[20px] xl:mx-0 mt-[60px] xl:mt-[120px]">
                    <Fade delay={100} left><div className="xl:max-w-[548px] mt-[35px] xl:mt-0 mx-[50px] xl:mx-0">
                        <img src={image} alt=""/>
                    </div></Fade>
                    <div className="xl:max-w-[525px]">
                        <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t("refer.howreward.text5")}</h1>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("refer.howreward.text6")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("refer.howreward.text7")}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default HowReward;