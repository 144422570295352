import React from "react";

import { useTranslation } from "react-i18next";

import image from '../../img/CopyTrading/img.png'

import Fade from 'react-reveal/Fade'

function HowItWorks () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[60px] xl:mt-[120px]">
                    <Fade left><div className="xl:max-w-[548px] mt-[35px] xl:mt-0 mx-[20px] xl:mx-0">
                        <img src={image} alt="" />
                    </div></Fade>
                    <div className="xl:max-w-[525px] mt-[40px] xl:mt-0">
                        <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t("copytrading.text16")}</h1>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("copytrading.text17")}</p>
                        <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t("copytrading.text18")}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default HowItWorks;