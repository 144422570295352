import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const productsData = [
    { 
        index: 0,
        title: 'Indices',
        gridcols: 'grid grid-cols-2 w-[420px]',
        rows: [
            { 
                "1": "ourspreads.market",
                "2": "ourspreads.spread",
                "3": "FTSE 100",
                "4": "1",
                "5": "Wall Street",
                "6": "2.4",
                "7": "Germany 40",
                "8": "1.2",
                "9": "Hong Kong HS50",
                "10": "50"
            }
        ]
    },
    { 
        index: 1,
        title: 'Forex',
        gridcols: 'grid grid-cols-4 w-[650px]',
        rows: [
            { 
                "1": "ourspreads.market",
                "2": "ourspreads.spread",
                "3": "ourspreads.average",
                "4": "AS (00:00-21:00)**",
                "5": "EUR/USD",
                "6": "0.6",
                "7": "1.04",
                "8": "0.85",
                "9": "USD/JPY",
                "10": "0.7",
                "11": "1.26",
                "12": "0.97",
                "13": "AUDUSD",
                "14": "0.6",
                "15": "1.33",
                "16": "1.07",
                "17": "GBP/USD",
                "18": "0.9",
                "19": "1.83",
                "20": "1.40",
            }
        ]
    },
    { 
        index: 2,
        title: 'Shares',
        gridcols: 'grid grid-cols-2 w-[650px]',
        rows: [
            { 
                "1": "ourspreads.market",
                "2": "ourspreads.spread",
                "3": "EUR/Spot Gold",
                "4": "0.3",
                "5": "Spot Silver (5000oz)",
                "6": "2",
                "7": "Oil - Brent Crude",
                "8": "2.8",
                "9": "Oil - US Crude",
                "10": "2.8",

            }
        ]
    },
    { 
        index: 3,
        title: 'Commodities',
        gridcols: 'grid grid-cols-4 w-[650px]',
        rows: [
            { 
                "1": "ourspreads.market",
                "2": "ourspreads.commision",
                "3": "ourspreads.online",
                "4": "ourspreads.phone",
                "5": "Hong Kong",
                "6": "0.25%",
                "7": "HKD50",
                "8": "HKD50",
                "9": "Singapore",
                "10": "0.10%",
                "11": "$10**",
                "12": "$15**",
                "13": "US",
                "14": "ourspreads.cents",
                "15": "$15",
                "16": "$25",
                "17": "UK",
                "18": "0.10%",
                "19": "£10",
                "21": "£15",
                "22": "Euro",
                "23": "0.10%",
                "24": "€10",
                "25": "€25",
                "26": "Australia",
                "27": "0.08%",
                "28": "AUD7",
                "29": "AUD7",
            }
        ]
    },
    { 
        index: 4,
        title: 'Cryptocurrencies',
        gridcols: 'grid grid-cols-2 w-[350px]',
        rows: [
            { 
                "1": "ourspreads.market",
                "2": "ourspreads.commision",
                "3": "Bitcoin",
                "4": "36",
                "5": "Bitcoin Cash",
                "6": "2",
                "7": "Ethereum",
                "8": "1.2",
            }
        ]
    }
];

function OurSpreads () {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };

    const activeData = productsData[activeIndex];;

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold text-center mx-[20px] xl:mx-0">{t('ourspreads.title')}</h1>
                <div className="flex justify-center flex-wrap gap-[20px] xl:gap-[50px] mt-[30px] xl:mt-[65px]">
                    {productsData.map((product) => (
                        <div 
                            className={activeIndex === product.index ? `spreads bg-[#DCF598] cursor-pointer` : `spreads bg-[#DDD] cursor-pointer`} 
                            key={product.index}
                            onClick={() => handleButtonClick(product.index)}
                        >
                            <p className="text-[14px] xl:text-[16px] inter text-[#212121]">{t(product.title)}</p>
                        </div>
                    ))}
                </div>
                <div className="mt-[40px] xl:mt-[80px] overflow-hidden overflow-x-auto mx-[20px] xl:mx-0">
                {activeData.rows.map((row, rowIndex) => (
                    <div key={rowIndex} className={`${activeData.gridcols} mx-auto`}>
                        {Object.keys(row).map((key, colIndex) => (
                            <div>
                                <p className="open-sans text-[14px] text-[#222B40] font-medium" key={colIndex} dangerouslySetInnerHTML={{ __html: t(row[key]) }} />
                                <div className="h-[1px] bg-[#222B40] w-auto mt-[15px] mb-[15px]"></div>
                            </div>                         
                        ))}
                    </div>
                ))}
                </div>
           </div>
        </div>
    );
}

export default OurSpreads;