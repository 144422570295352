import React from "react";
import { useTranslation } from 'react-i18next';

const Execution = () => {
  const { t } = useTranslation(); // Используем хук useTranslation для получения функции t для перевода текста

  return (
    <div className="max-w-[1280px] mx-auto">
      <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold mx-[20px] xl:mx-0">{t('executionTitle')}</h1>
      <div className="flex justify-between flex-col xl:flex-row mx-[20px] xl:mx-0 mt-[30px] xl:mt-[35px]">
        <div className="xl:max-w-[525px]">
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121]">{t('fastAndReliable')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('cuttingEdgePlatforms')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121] mt-[35px]">{t('vastNetworkTitle')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('vastNetworkDescription')}</p>
        </div>
        <div className="xl:max-w-[525px] mt-[35px] xl:mt-0">
          <p className="inter text-[14px] xl:text-[16px] font-medium text-[#212121]">{t('unparalleledTitle')}</p>
          <p className="inter text-[14px] xl:text-[16px] font-light text-[#212121] mt-[15px]">{t('unparalleledDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Execution;
