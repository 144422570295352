import React from "react";

import { useTranslation } from "react-i18next";

import award1 from '../../img/Home/award1.svg'
import award2 from '../../img/Home/award2.svg'
import award3 from '../../img/Home/award3.svg'
import award4 from '../../img/Home/award4.svg'
import award5 from '../../img/Home/award5.svg'
import award6 from '../../img/Home/test (1).png'
import award7 from '../../img/Home/test (2).png'
import award8 from '../../img/Home/test (3).png'
import award9 from '../../img/Home/test (4).png'
import award10 from '../../img/Home/test (5).png'
import award11 from '../../img/Home/test (6).png'
import award12 from '../../img/Home/test (7).png'
import award13 from '../../img/Home/test (8).png'
import award14 from '../../img/Home/test (9).png'
import award15 from '../../img/Home/test (10).png'

function Award () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold text-center mx-[20px] xl:mx-0">{t("award.title")}</h1>
                <p className="max-w-[525px] mt-[15px] mx-[20px] xl:mx-auto inter text-[14px] xl:text-[16px] text-[#212121] font-light text-center">{t("award.text")}</p>
                <div className="gap-[60px] grid grid-cols-2 xl:grid-cols-5 mx-[20px] xl:mx-0 mt-[30px] xl:mt-[65px]">
                    <img src={award1} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award2} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award3} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award4} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award5} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award6} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award7} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award8} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award9} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award10} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award11} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award12} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award13} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award14} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                    <img src={award15} alt="" className="opacity-50 xl:w-[146.53px] xl:h-[105px] w-[69.776px] h-[50px] mx-auto"/>
                </div>
           </div>
        </div>
    );
}

export default Award;
