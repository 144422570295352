import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import button from '../../img/Home/button.png'

const faqData = [
    {
        question: 'copytrading.text19',
        answer: 'copytrading.text20'
    },
    {
        question: 'copytrading.text21',
        answer: 'copytrading.text22'
    },
    {
        question: 'copytrading.text23',
        answer: 'copytrading.text24'
    },
]
function FAQ () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col-reverse xl:flex-row justify-between mx-[20px] xl:mx-0">
                    <h1 className="text-[#02995A] inter text-[22px] xl:text-[35px] font-bold">{t("faq.title")}</h1>
                </div>
                <div className="flex justify-between flex-col mx-[20px] xl:mx-0 mt-[15px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between mt-[20px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#DCF598]' : 'bg-[#F3F2F2] h-[54px]'}`}>
                                <div className='mx-[25px]'>
                                    <p className='mt-[15px] manrope text-[14px] xl:text-[16px] text-[#493843] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                    <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText(index)}>
                                    <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
           </div>
        </div>
    );
}

export default FAQ;