import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import girl from '../img/Products/shares.png'

import Marquee from "react-fast-marquee";
import Fade from "react-reveal/Fade";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import Icon1 from '../img/Products/iicon.svg'
import Icon2 from '../img/Products/iicon(1).svg'
import Icon3 from '../img/Products/iicon(2).svg'

import OpenAccount from '../components/Home/OpenAccount'

function Shares () {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [swiper, setSwiper] = useState({});

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[80px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t('shares.page.title')}</span></h1>
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold">{t('shares.page.unleashing')}</h1>
                        <p className="hidden xl:block mt-[35px] max-w-[525px] inter text-[16px] text-[#212121] font-light">{t('shares.page.topforex')}</p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.one">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('shares.page.button')}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px]">
                        <Fade duration={1000}><img src={girl} alt="" className="xl:ml-[130px] mx-auto"/></Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <div className="buttontwo mt-[50px]">
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('shares.page.button')}</p>
                            </div>
                        </div>
                        <p className="md:text-center xl:text-left mx-[20px] block xl:hidden mt-[50px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t('shares.page.topforex')}</p>
                    </div>
                </div>
           </div>
           <Marquee autoFill className="bg-[#DCF598] py-[15px] mt-[60px] xl:mt-[90px]">
                <p className="inter text-[20px] xl:text-[30px] font-semibold text-[#212121] mr-[20px]">{t('shares.page.shares')}</p>
           </Marquee>
           <div className="max-w-[1280px] xl:mx-auto mt-[80px] xl:mt-[160px] mx-[20px]">
                <div className='flex mt-[20px] gap-[10px] justify-end'>
                    <div onClick={() => swiper.slidePrev()}>
                        <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="29.5" y="29.5" width="29" height="29" rx="14.5" transform="rotate(-180 29.5 29.5)" stroke="#493843"/>
                            <path d="M7.64645 14.6464C7.45118 14.8417 7.45118 15.1583 7.64645 15.3536L10.8284 18.5355C11.0237 18.7308 11.3403 18.7308 11.5355 18.5355C11.7308 18.3403 11.7308 18.0237 11.5355 17.8284L8.70711 15L11.5355 12.1716C11.7308 11.9763 11.7308 11.6597 11.5355 11.4645C11.3403 11.2692 11.0237 11.2692 10.8284 11.4645L7.64645 14.6464ZM22 14.5L8 14.5V15.5L22 15.5V14.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                    <div onClick={() => swiper.slideNext()}>
                        <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#493843"/>
                            <path d="M22.3536 15.3536C22.5488 15.1583 22.5488 14.8417 22.3536 14.6464L19.1716 11.4645C18.9763 11.2692 18.6597 11.2692 18.4645 11.4645C18.2692 11.6597 18.2692 11.9763 18.4645 12.1716L21.2929 15L18.4645 17.8284C18.2692 18.0237 18.2692 18.3403 18.4645 18.5355C18.6597 18.7308 18.9763 18.7308 19.1716 18.5355L22.3536 15.3536ZM8 15.5L22 15.5L22 14.5L8 14.5L8 15.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                </div>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={10}
                    slidesPerView={2.1}
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        300: {
                        slidesPerView: 1,
                        },
                        768: {
                        slidesPerView: 2.1,
                        }
                    }}
                    className="mt-[25px]"
                >
                    <SwiperSlide>
                        <div className="xl:w-[593px] xl:h-[379px] bg-[#F3F2F2] rounded-[10px] border border-[black] pb-[10px] xl:pb-0">
                            <img src={Icon1} alt="" className="xl:mt-[40px] mt-[20px] xl:mx-[35px] mx-[20px]"/>
                            <p className="open-sans text-[#212121] font-semibold text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px]">{t('shares.page.swiper.slide1.title')}</p>
                            <p className="open-sans text-[#212121] font-light text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px] max-w-[525px]">{t('shares.page.swiper.slide1.text')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="xl:w-[593px] xl:h-[379px] bg-[#DCF598] rounded-[10px] border border-[black] pb-[10px] xl:pb-0">
                            <img src={Icon2} alt="" className="xl:mt-[40px] mt-[20px] xl:mx-[35px] mx-[20px]"/>
                            <p className="open-sans text-[#212121] font-semibold text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px]">{t('shares.page.swiper.slide2.title')}</p>
                            <p className="open-sans text-[#212121] font-light text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px] max-w-[525px]">{t('shares.page.swiper.slide2.text')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="xl:w-[593px] xl:h-[379px] bg-[#F3F2F2] rounded-[10px] border border-[black] pb-[10px] xl:pb-0">
                            <img src={Icon3} alt="" className="xl:mt-[40px] mt-[20px] xl:mx-[35px] mx-[20px]"/>
                            <p className="open-sans text-[#212121] font-semibold text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px]">{t('shares.page.swiper.slide3.title')}</p>
                            <p className="open-sans text-[#212121] font-light text-[14px] xl:text-[16px] mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-[35px] max-w-[525px]">{t('shares.page.swiper.slide3.text')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <OpenAccount/>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default Shares;