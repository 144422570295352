import React from "react";

import { useTranslation } from "react-i18next";

import manager1 from '../../img/AboutUs/managers2.png'
import manager2 from '../../img/AboutUs/managers1.png'

function MeetManagers () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <h1 className="inter text-[22px] xl:text-[35px] font-bold text-[#02995A]">{t("aboutus.text2")}</h1>
                <div className="flex flex-col xl:flex-row mt-[40px] xl:mt-[65px] justify-between">
                    <div>
                        <img src={manager1} alt="" />
                        <p className="text-[16px] xl:text-[18px] inter text-[#212121] mt-[30px] font-semibold">{t("aboutus.text3")}</p>
                        <p className="text-[16px] xl:text-[18px] inter text-[#212121] mt-[15px] font-medium">{t("aboutus.text4")}</p>
                        <p className="text-[14px] xl:text-[16px] inter text-[#212121] mt-[15px] font-light max-w-[525px]">{t("aboutus.text5")}</p>
                        <p className="text-[14px] xl:text-[16px] inter text-[#212121] mt-[15px] font-light max-w-[525px]">{t("aboutus.text6")}</p>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <img src={manager2} alt="" />
                        <p className="text-[16px] xl:text-[18px] inter text-[#212121] mt-[30px] font-semibold">{t("aboutus.text7")}</p>
                        <p className="text-[16px] xl:text-[18px] inter text-[#212121] mt-[15px] font-medium">{t("aboutus.text8")}</p>
                        <p className="text-[14px] xl:text-[16px] inter text-[#212121] mt-[15px] font-light max-w-[525px]">{t("aboutus.text9")}</p>
                        <p className="text-[14px] xl:text-[16px] inter text-[#212121] mt-[15px] font-light max-w-[525px]">{t("aboutus.text10")}</p>
                        <p className="text-[14px] xl:text-[16px] inter text-[#212121] mt-[15px] font-light max-w-[525px]">{t("aboutus.text11")}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default MeetManagers;