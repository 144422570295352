import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import girl from '../img/Products/img3.png'
import com from '../img/Products/image.png'

import Marquee from "react-fast-marquee";
import Fade from "react-reveal/Fade";

import icon from "../img/Products/icon04.svg"
import icon1 from "../img/Products/icon02.svg"
import icon2 from "../img/Products/icon01.svg"
import icon3 from "../img/Products/icon01.svg"

function Commodities () {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="mt-[80px] xl:mt-[200px] flex flex-col md:items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 max-w-[525px] text-[#212121] inter text-[22px] xl:text-[35px] font-bold"><span className="text-[#02995A]">{t('commodities.page.title')}</span></h1>
                        <p className="hidden xl:block mt-[35px] max-w-[525px] inter text-[16px] text-[#212121] font-light">{t('commodities.page.text')}</p>
                        <div className="hidden xl:block cursor-pointer">
                            <a href="https://user.topforex.one">
                                <div className="buttontwo mt-[50px]">
                                    <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('tradeNow')}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center pt-[50px] xl:pt-[120px] mx-[20px] xl:mx-0">
                        <Fade duration={1000}><img src={girl} alt="" className="xl:ml-[130px] mx-auto"/></Fade>
                        <div className="block xl:hidden relative z-[999] cursor-pointer">
                            <div className="buttontwo mt-[50px]">
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('tradeNow')}</p>
                            </div>
                        </div>
                        <p className="md:text-center xl:text-left block xl:hidden mt-[50px] max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light">{t('commodities.page.text')}</p>
                    </div>
                </div>
           </div>
           {/* Marque */}
           <Marquee autoFill className="bg-[#DCF598] py-[15px] mt-[60px] xl:mt-[90px]">
                <p className="inter text-[20px] xl:text-[30px] font-semibold text-[#212121] mr-[40px]">{t('commodities.page.marque')}</p>
           </Marquee>
           {/* Why Trade */}
           <div className="bg-[#F5F4F4] mt-[90px] xl:mt-[160px]">
                <div className="max-w-[1280px] mx-auto pt-[20px] xl:pt-[65px] pb-[20px] xl:pb-[65px] flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col items-center xl:items-start">
                        <h1 className="mx-[20px] text-center xl:text-left xl:mx-0 text-[#212121] inter text-[20px] xl:text-[30px] font-bold max-w-[210px]">{t('commodities.page.why')}</h1>
                        <a href="https://user.topforex.one" className="buttontwo mt-[20px] mb-[20px] xl:mb-0 xl:mt-[50px]">
                            <div>
                                <p className="text-[#212121] inter text-[14px] xl:text-[16px]">{t('tradeNow')}</p>
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col xl:flex-row gap-[34px] mx-[20px] xl:mx-0">
                        <div>
                            <div className="xl:w-[384px] xl:h-[208px] rounded-[10px] bg-[#D9D9D9]"> 
                                <img src={icon} alt="" className="pt-[15px] xl:pt-[30px] mx-[15px] xl:mx-[27px]"/>
                                <p className="pb-[15px] xl:pb-0 mt-[15px] xl:mt-[15px] mx-[15px] xl:mx-[27px] inter text-[#212121] font-light max-w-[331px] text-[14px] xl:text-[16px]">{t('commodities.page.why1')}</p>
                            </div> 
                            <div className="xl:w-[384px] xl:h-[208px] rounded-[10px] bg-[#D9D9D9] mt-[20px] xl:mt-[34px]"> 
                                <img src={icon1} alt="" className="pt-[15px] xl:pt-[30px] mx-[15px] xl:mx-[27px]"/>
                                <p className="pb-[15px] xl:pb-0 mt-[15px] xl:mt-[15px] mx-[15px] xl:mx-[27px] inter text-[#212121] font-light max-w-[331px] text-[14px] xl:text-[16px]">{t('commodities.page.why2')}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="xl:w-[384px] xl:h-[208px] rounded-[10px] bg-[#D9D9D9]"> 
                                <img src={icon2} alt="" className="pt-[15px] xl:pt-[30px] mx-[15px] xl:mx-[27px]"/>
                                <p className="pb-[15px] xl:pb-0 mt-[15px] xl:mt-[15px] mx-[15px] xl:mx-[27px] inter text-[#212121] font-light max-w-[331px] text-[14px] xl:text-[16px]">{t('commodities.page.why3')}</p>
                            </div> 
                            <div className="xl:w-[384px] xl:h-[208px] rounded-[10px] bg-[#D9D9D9] mt-[20px] xl:mt-[34px]"> 
                                <img src={icon3} alt="" className="pt-[15px] xl:pt-[30px] mx-[15px] xl:mx-[27px]"/>
                                <p className="pb-[15px] xl:pb-0 mt-[15px] xl:mt-[15px] mx-[15px] xl:mx-[27px] inter text-[#212121] font-light max-w-[331px] text-[14px] xl:text-[16px]">{t('commodities.page.why4')}</p>
                            </div> 
                        </div>
                    </div>
                </div>
           </div>
           {/* ways to trade */}
           <div className="max-w-[1280px] mx-auto mt-[90px] xl:mt-[160px]">
                <h1 className="text-black inter text-[22px] xl:text-[35px] font-bold text-center mx-[20px] xl:mx-0">{t('commodities.page.how')}</h1>   
                <div className="xl:mt-[81px] mt-[30px] mx-[20px]">
                    <div className="grid grid-cols-3 max-w-[620px] mx-auto">
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]"></p>
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]">{t('commodities.page.how1')}</p>
                    </div>
                    <div className="h-[1px] bg-black max-w-[620px] mx-auto mt-[15px] mb-[15px]"></div>
                    <div className="grid grid-cols-3 max-w-[620px] mx-auto">
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]">{t('commodities.page.how2')}</p>
                        <p className="text-[#222B40] open-sans font-light text-[12px] xl:text-[14px]">{t('commodities.page.how3')}</p>
                    </div>
                    <div className="h-[1px] bg-black max-w-[620px] mx-auto mt-[15px] mb-[15px]"></div>
                    <div className="grid grid-cols-3 max-w-[620px] mx-auto">
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]">{t('commodities.page.how4')}</p>
                        <p className="text-[#222B40] open-sans font-light text-[12px] xl:text-[14px]">{t('commodities.page.how5')}</p>
                    </div>
                    <div className="h-[1px] bg-black max-w-[620px] mx-auto mt-[15px] mb-[15px]"></div>
                    <div className="grid grid-cols-3 max-w-[620px] mx-auto">
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]">{t('commodities.page.how6')}</p>
                        <p className="text-[#222B40] open-sans font-light text-[12px] xl:text-[14px]">{t('commodities.page.how7')}</p>
                    </div>
                    <div className="h-[1px] bg-black max-w-[620px] mx-auto mt-[15px] mb-[15px]"></div>
                    <div className="grid grid-cols-3 max-w-[620px] mx-auto">
                        <p className="text-[#222B40] open-sans font-semibold text-[12px] xl:text-[14px]">{t('commodities.page.how8')}</p>
                        <p className="text-[#222B40] open-sans font-light text-[12px] xl:text-[14px]">{t('commodities.page.how9')}</p>
                    </div>
                    <div className="h-[1px] bg-black max-w-[620px] mx-auto mt-[15px]"></div>
                </div>        
           </div>
           {/* what is forex trading */}
           <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[90px] xl:mt-[160px]  pb-[80px] xl:pb-[160px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <h1 className="text-black inter text-[22px] xl:text-[35px] font-bold">{t('commodities.page.what')}</h1>   
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] mt-[20px] xl:mt-[30px] font-medium">{t('commodities.page.what1')}</p>
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-medium">{t('commodities.page.what2')}</p>
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light mt-[15px]">{t('commodities.page.what3')}</p>
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light mt-[15px]">{t('commodities.page.what4')}</p>
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light mt-[15px]">{t('commodities.page.what5')}</p>
                        <p className="max-w-[525px] inter text-[14px] xl:text-[16px] text-[#212121] font-light mt-[15px]">{t('commodities.page.what6')}</p>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <Fade duration={1000}><img src={com} alt=""/></Fade>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Commodities;